<template>
  <Page title="Task List" :headerFullWidth="true">
    <template #right>
      <div class="flex flex-row items-center gap-x-4 h-14 h-fit">
        <IconField iconPosition="left">
          <InputIcon>
            <icon icon="search" class="text-surface-800" />
          </InputIcon>
          <InputText v-model="query" placeholder="Search your tasks..." size="large" type="text" />
        </IconField>

        <!-- <Btn
          size="lg"
          :severity="createToggle ? 'tertiary' : 'primary-black'"
          class="h-full"
          @click="createNew">
          <icon :icon="['fas', 'plus']" />
          Create new
        </Btn> -->
      </div>
    </template>
    <ScheduleListHeader
      :interval="interval"
      :skipDuration="skipDuration"
      :setInterval="setInterval"
      :setView="setView"
      :setDate="setDate"
      :view="view"
      :date="date"
    >
      <template #actions>
        <Calendar
          class="mr-2 block xl:hidden"
          v-model="selectedDate"
          :touchUI="true"
          @date-select="handleSelectFromCalendar"
        >
          <template #footer>
            <div class="grid grid-cols-4 gap-4">
              <Btn @click="toToday" severity="tertiary"> Today </Btn>
              <Btn @click="() => goToInterval('day')" severity="tertiary"> Day </Btn>
              <Btn @click="() => goToInterval('week')" severity="tertiary"> Week </Btn>
              <Btn @click="() => goToInterval('month')" severity="tertiary"> Month </Btn>
            </div>
          </template>
        </Calendar>
      </template>
    </ScheduleListHeader>
    <div class="grid grid-cols-4">
      <div class="col-span-4 xl:col-span-3">
        <template v-if="view === 'today'">
          <ScheduleListSection
            :title="`Today: ${today}`"
            :query="query"
            :filters="{
              item_status: 'p||f',
              start_date: `>${startOfToday}&&<${endOfToday}`
            }"
          />

          <ScheduleListSection
            :title="`Tomorrow: ${tomorrow}`"
            :query="query"
            :filters="{
              item_status: 'p||f',
              start_date: `>${startOfTomorrow}&&<${endOfTomorrow}`
            }"
          />
        </template>

        <template v-if="view === 'calendar'">
          <ScheduleListSection
            title=""
            :query="query"
            :filters="{
              item_status: 'p||f',
              start_date: `>${startDate}&&<${endDate}`
            }"
          />
        </template>
      </div>
      <div class="col-span-1 ml-6 justify-end hidden xl:flex">
        <Calendar
          class="w-80"
          :pt="{
            weeklabelcontainer: '!w-6 !h-8',
            daylabel: '!w-5 !h-7',
            day: 'md:!p-0'
          }"
          :ptOptions="{
            mergeProps: true
          }"
          v-model="selectedDate"
          @date-select="handleSelectFromCalendar"
          inline
          showWeek
        />
      </div>
    </div>
  </Page>

  <MiniModal @close="handleCloseProofOfWork" ref="proofOfWorkModal">
    <template #title>
      <span class="flex flex-col">
        Task: {{ selectedItem.item_name }}
        <span class="text-cool-gray-700 text-base font-normal">
          {{
            $f.truncate(
              selectedItem.asAssemblyPath
                .slice(Math.max(selectedItem.asAssemblyPath.length - 2, 0))
                .join(' → '),
              40
            )
          }}
        </span>
      </span>
    </template>
    <template #body>
      <ScheduleProofOfWork :item="selectedItem" :approval="selectedApproval" />
    </template>
    <template #footer>
      <ProgressApprovalBar :confirmed="onConfirm"> Approve task </ProgressApprovalBar>
    </template>
  </MiniModal>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import moment from 'moment-timezone'
import { useStore } from 'vuex'
import InputIcon from 'primevue/inputicon'
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import ScheduleListSection from '@/components/schedule/list/ScheduleListSection.vue'
import ScheduleListHeader from '@/components/schedule/list/ScheduleListHeader.vue'
import useApproval from '@/components/composables/Approval'
import ProgressApprovalBar from '@/components/progress/ProgressApprovalBar.vue'
import ScheduleProofOfWork from '@/components/schedule/list/ScheduleProofOfWork.vue'
import eventBus from '@/eventBus'

const store = useStore()
const { clearApproval } = useApproval()
const interval = ref('')
const query = ref('')
const view = ref('today')
const date = ref(moment().format('X'))
const selectedDate = ref(moment().format('LLLL'))
const proofOfWorkModal = ref()
const selectedItem = ref()
const selectedApproval = ref()

const timestamp = computed(() => date.value * 1000)

const startDate = computed(() => moment(timestamp.value).startOf(interval.value).format('X'))
const endDate = computed(() => moment(timestamp.value).endOf(interval.value).format('X'))

const setInterval = (value) => (interval.value = value)

const setDate = (value) => (date.value = value)

const setView = (value) => (view.value = value)

const skipDuration = (direction) => {
  if (direction === 'backward') {
    date.value = moment(timestamp.value).subtract(1, interval.value).format('X')
    return
  }
  date.value = moment(timestamp.value).add(1, interval.value).format('X')
}

const handleSelectFromCalendar = (d) => {
  const formattedDate = moment(d).format('X')
  setDate(formattedDate)
  setView('calendar')
  setInterval('day')
}

const handleCloseProofOfWork = () => {
  clearApproval()
}

const onConfirm = () => {
  proofOfWorkModal.value.close()
  clearApproval()
}

const goToInterval = (value) => {
  setView('calendar')
  setInterval(value)
}

const toToday = () => {
  setView('today')
  setDate(moment().format('X'))
}

const startOfToday = computed(() => moment(timestamp.value).endOf('day').format('X'))
const endOfToday = computed(() => moment(timestamp.value).endOf('day').format('X'))

const startOfTomorrow = computed(() =>
  moment(timestamp.value).add(1, 'day').endOf('day').format('X')
)
const endOfTomorrow = computed(() => moment(timestamp.value).add(1, 'day').endOf('day').format('X'))

const today = computed(() => moment().format('ddd, MMMM DD'))
const tomorrow = computed(() => moment().add(1, 'day').format('ddd, MMMM DD'))

const { session } = useApproval()

onMounted(() => {
  moment.tz.setDefault('UTC')
  const userId = store.state.session.user.user_id
  session.value.role = 'vendor'
  session.value.userId = userId
  eventBus.$on('three-way-approval-select', ({ item, approval }) => {
    proofOfWorkModal.value.open()
    selectedItem.value = item
    selectedApproval.value = approval
  })
})

onBeforeUnmount(() => {
  eventBus.$off('three-way-approval-select')
})
</script>
