<template>
  <SectionContainer
    v-if="section"
    :section="section"
    :canFetchMore="canFetchMore"
    :fetchMore="fetchTasks"
    :featured="false"
    :asCard="false"
    :condensed="true"
    :loading="loading"
    type="task"
    fetchingMessage="Retrieving tasks..."
    noResultsMessage="No tasks to show"
  >
    <template #groupTitle="{ item }">
      <span class="text-xl">
        Job site:
        <span class="font-bold">{{ item.name }}{{ item.address ? `, ${item.address}` : '' }}</span>
      </span>
    </template>
  </SectionContainer>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, toRef, watch } from 'vue'
import SectionContainer from '@/components/ui/SectionContainer.vue'
import Statuses from '@/../imports/api/Statuses'
import { useStore } from 'vuex'

const store = useStore()

const limit = ref(1)
const offset = ref(0)
const items = ref([])
const loading = ref(0)
const groupedByProject = ref({})
const projects = ref([])
const canFetchMore = ref(true)

// TODO: add limit as prop
const props = defineProps({
  filters: {
    type: Object,
    default: () => {}
  },
  query: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  }
})

const filters = toRef(props, 'filters')
const query = toRef(props, 'query')

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    const keys = Object.keys(rv)
    if (keys.includes(x[key].toString())) {
      rv[x[key]].items.push(x)
      return rv
    }
    if (x[key]) {
      const address = `${x.project_suite || ''}${x.project_address || ''}${x.project_city || ''}`
      rv[x[key]] = {
        address,
        name: x.project_name,
        key: x[key],
        items: [x]
      }
    }
    return rv
  }, {})
}

async function fetchTasks() {
  loading.value = 1
  const { set, total } = await store.dispatch('ItemAssignee/search', {
    searchPhrase: query.value,
    filters: {
      item_assignee_object_id: store.state.session.user.user_id,
      item_assignee_status: Statuses.statuses.Active,
      ...filters.value
    },
    offset: 0,
    limit: 1000
  })

  if (total < limit.value) canFetchMore.value = false
  items.value = set
  offset.value = offset.value + limit.value
  const grouped = groupBy(set, 'project_id')
  groupedByProject.value = grouped
  loading.value = 0
}

const section = computed(() => {
  return {
    title: props.title,
    description: props.description,
    items: items,
    groups: groupedByProject.value
  }
})

onMounted(() => {
  fetchTasks()
})

watch(
  () => filters.value,
  () => {
    c.throttle(
      () => {
        fetchTasks()
      },
      { delay: 400 }
    )
  }
)
</script>
