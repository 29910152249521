<template>
  <div class="grid grid-cols-2 border-b border-cool-gray-200 mb-6 pb-4">
    <div class="flex justify-start items-center">
      <span>
        <h2 class="!font-medium">{{ currentMonthAndYear }}</h2>
        <p class="text-cool-gray-600">
          {{ dateTagLine }}
        </p>
      </span>
      <div class="flex">
        <Btn
          class="ml-6"
          size="lg"
          :link="true"
          severity="tertiary"
          @click="() => skipDuration('backward')"
        >
          <font-awesome-icon :icon="['far', 'chevron-left']" />
        </Btn>
        <Btn
          class="ml-2"
          size="lg"
          :link="true"
          severity="tertiary"
          @click="() => skipDuration('forward')"
        >
          <font-awesome-icon :icon="['far', 'chevron-right']" />
        </Btn>
      </div>
    </div>
    <div class="flex justify-end items-center">
      <slot name="actions"> </slot>
      <Btn
        @click="toToday"
        size="lg"
        :severity="view === 'today' ? 'secondary' : 'tertiary'"
        class="mr-4 hidden xl:block"
      >
        Today
      </Btn>
      <BtnBar
        :collapse="false"
        severity="tertiary"
        class="hidden xl:block"
        :link="true"
        size="lg"
        :actions="[
          {
            title: 'Day',
            action: () => goToInterval('day'),
            btnClass: interval === 'day' ? '!bg-cool-gray-100' : ''
          },
          {
            title: 'Week',
            action: () => goToInterval('week'),
            btnClass: interval === 'week' ? '!bg-cool-gray-100' : ''
          },
          {
            title: 'Month',
            action: () => goToInterval('month'),
            btnClass: interval === 'month' ? '!bg-cool-gray-100' : ''
          }
        ]"
      >
      </BtnBar>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'
import moment from 'moment'

const props = defineProps({
  interval: {
    type: String,
    default: ''
  },
  date: {
    type: Object
  },
  setInterval: {
    type: Function
  },
  skipDuration: {
    type: Function
  },
  setDate: {
    type: Function
  },
  setView: {
    type: Function
  },
  view: {
    type: String
  }
})

const interval = toRef(props, 'interval')
const date = toRef(props, 'date')

const timestamp = computed(() => date.value * 1000)

const currentMonthAndYear = computed(() => moment(timestamp.value).format('MMMM YYYY'))

const dateTagLine = computed(() => {
  const m = moment(timestamp.value)
  if (interval.value === 'month') {
    return `${m.format('[The month] [of] MMMM')}`
  }
  if (interval.value === 'day') {
    return `${m.format('[The] Do [of] MMMM')}`
  }
  const first = m.format('[Week] [of] MMM')
  const dateRange = `${m.startOf('week').format('D')} - ${m.endOf('week').format('D')}`
  return `${first} ${dateRange}`
})

const goToInterval = (value) => {
  props.setView('calendar')
  props.setInterval(value)
}

const toToday = () => {
  props.setView('today')
  props.setDate(moment().format('X'))
}
</script>
